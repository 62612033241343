/* Panel
------------------------------------------------------------------- */

.border-dotted {
  border: 1px dotted $grey-color;
  padding: 1em;
  border-radius: $global-radius;
}


/* Colors
------------------------------------------------------------------- */

.ci-1 { color: $ci-1; }
.ci-2 { color: $ci-2; }
.ci-3 { color: $ci-3; }
.ci-4 { color: $ci-4; }
/* 28.09.2023 
Add more classes for colours that were created in _color_scheme_simplicity.scss: */
.ci-5 { color: $ci-5; }
.ci-6 { color: $ci-6; }

.ci-1-bg { background: $ci-1; }
.ci-2-bg { background: $ci-2; }
.ci-3-bg { background: $ci-3; }
.ci-4-bg { background: $ci-4; }
/* 28.09.2023 
Add more classes for background colours that were created in _color_scheme_simplicity.scss: */
.ci-5-bg { background: $ci-5; }
.ci-6-bg { background: $ci-6; }
/*
ci-7 ... usage see also "CSS-Classes for special boxes" below
 */

.alert-color { color: $alert-color; }
.success-color { color: $success-color; }
.warning-color { color: $warning-color; }
.info-color { color: $info-color; }

.alert-color-bg { background: $alert-color; }
.success-color-bg { background: $success-color; }
.warning-color-bg { background: $warning-color; }
.info-color-bg { background: $info-color; }


.grey { color: $grey-color; }
.grey-light { color: $grey-color-light; }
.grey-dark { color: $grey-color-dark; }


/* CSS-Classes for special boxes 
------------------------------------------------------------------- */
/* 28.09.2023 
Classes with background colours from above for special boxes, see also _color_scheme_simplicity.scss 
Starting with .specialbox-bg7  with background: $ci-7 because ci-7 is a new colour not yet used
elsewhere - can obviously be expanded to include $ci-* something. */

/* Line-height for specialbox: 
Potentially used in several places.
Note that $base-line-height of 1.5 is defined in /assets/css/simplicity.scss which is 
a bit strange, let's add another one here: */
$specialbox-line-height: 1.1;

.specialbox-bg7 {
  background: $ci-7; 
  float: left; 
/*  line-height: 7em; */
  line-height: $specialbox-line-height;
/*  width: 7em; */
/*  For 3 boxes horizontally aligned 33 %, but now changed to 4 boxes, the 4th less wide 
  width: 33%;
  width: 25%;*/
  width: 27.5%;
/*  note 
  height: auto;
  obviously creates uneven boxes if there are several boxes with different text lengths.
*/
  height: 5.5em;
/* padding top right bottom left */
  padding: 0.2em 0 0 0.1em;
  border:3px solid  $grey-color-light;
}

.specialbox-bg8 {
  background: $ci-8; 
  float: left; 
  line-height: $specialbox-line-height;
  width: 27.5%;
  height: 5.5em;
  padding: 0.2em 0 0 0.1em;
  border:3px solid  $grey-color-light;
}

.specialbox-bg9 {
  background: $ci-9; 
  float: left; 
  line-height: $specialbox-line-height;
  width: 27.5%;
  height: 5.5em;
  padding: 0.2em 0 0 0.1em;
  border:3px solid  $grey-color-light;
}

/* Special box without background for logo or another image */
.specialbox-bg10 {
/*  background: $ci-10; */
  float: left;
  line-height: $specialbox-line-height;
  width: 17%;
  height: 5.5em;
  padding: 0.2em 0 0 0.1em;
/* No border for this logo box:   
  border:3px solid  $grey-color-light; */
}

/* Special box without background but with border for logo or another image */
.specialbox-bg11 {
/*  background: $ci-11; */
  float: left;
  line-height: $specialbox-line-height;
  width: 17%;
  height: 5.5em;
  padding: 0.2em 0 0 0.1em;
  border:3px solid  $grey-color-light;
}

.specialbox-bg12 {
  background: $ci-12; 
  float: left; 
  line-height: $specialbox-line-height;
  width: 27.5%;
  height: 5.5em;
  padding: 0.2em 0 0 0.1em;
  border:3px solid  $grey-color-light;
}


/* CSS-Classes to add margin at top or bottom
------------------------------------------------------------------- */

.tem1 { margin-top: 1em !important; }
/* .tem2 { margin-top: 2em !important; }  
Changed 28.06.2018 --rw: */ 
.tem2 { margin-top: 0.2em !important; }
.tem3 { margin-top: 3em !important; }
.tem4 { margin-top: 4em !important; }

.bem1 { margin-bottom: 1em !important; }
/* .bem2 { margin-bottom: 2em !important; }
Changed 28.06.2018 --rw: */
.bem2 { margin-bottom: 0.2em !important; }
.bem3 { margin-bottom: 3em !important; }
.bem4 { margin-bottom: 4em !important; }

