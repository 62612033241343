/* Footnotes
------------------------------------------------------------------- */

.footnotes:before {
    content: "";
    position: absolute;
    height: 1px;
    width: 2em;
    margin-top: -10px;
    border-bottom: 1px solid  $line-color;
}
.footnotes {
    margin-top: 4em;
}
.footnotes ol {
    font-size: $font-size-small;
}
.footnotes p {
    font-size: inherit;
    margin-bottom: 0;
}