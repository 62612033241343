/* Footer
------------------------------------------------------------------- */

#footer {
  margin-top: 7em;
  padding-bottom: 2em;
  letter-spacing: 1px;
/* too small for mobile, try $font-size-h6, see _sass/04_typography/_typography_modular_scale_1em.scss
   06.04.2021 (digi-oek.ch), 04.11.2022 wuergler-it.ch --rw.
  line-height: $font-size-small;
*/
  line-height: $font-size-h6;
  color: $footer-color;
/* Footer navigation should have a smaller font size (or delete elements ...). 
   18px would be same as #tnav in /_sass/05_layout/_navigation.scss 09.10.2022, 04.11.2022 wuergler-it.ch --rw.
*/
  font-size: $font-size-small;
}
#footer a {
  border: 0;
  color: $footer-color;
}
#footer a:hover {
    color: $ci-1;
}
#footer ul.inline-list li:before {
	// middot
	content: '';
	padding: 0 .5em;
}
#footer ul.inline-list li:first-child:before {
	content: '';
	padding: 0;
}
#footer ul.inline-list li:first-child {
}

// Meta Information beneath article content
article footer:before {
    position: absolute;
    content:'';
    height: 1px;
    width: 4em;
    background-color: $footer-color;
}
article footer {
    margin: 2em auto 3em auto;
    color: $footer-color;
}
article footer p {
    padding-top: .25em;
}
