/* Modular Scale 1em
   You can use different modular scales with *Simplicity*
   Just configure the modular scale in _config.yml you want to use.

   Font Sizes
------------------------------------------------------------------- */

$base-font-size                 : 18px;
$base-font-size-medium-screen   : 22px;
$base-font-size-large-screen    : 26px;
$font-size-p                    : 1em;
/* changed 23.05.2018 --rw:
$font-size-h1                   : 1em;
$font-size-h1                   : 1.424em; */ 
/* changed 02.01.2021 --rw */
$font-size-h1                   : 1.800em; 
$font-size-h1                   : 1.62em; 
/* changed 23.05.2018 --rw:
$font-size-h2                   : 1em;
$font-size-h2                   : 1.266em; */ 
/* changed 02.01.2021 --rw */
$font-size-h2                   : 1.5em;
$font-size-h2                   : 1.35em;
/* changed 23.05.2018 --rw:
$font-size-h3                   : 1em; 
$font-size-h3                   : 1.125em; */
/* changed 02.01.2021 --rw
$font-size-h3                   : 1.25em; */
$font-size-h3                   : 1.125em;
$font-size-h4                   : 1em;
$font-size-h5                   : 1em;
/* font-size-h6 is also used for line-height (!) of #footer in _sass/05_layout/_footer.scss */
$font-size-h6                   : 1em;
/* changed 15.05.2018 --rw: 
$font-size-small                : 1em; */
$font-size-small                : .75em; 
